/**
 * @flow
 */

import React from 'react';
import { Route } from 'react-router-dom';
import { AuthenticatedRoute } from '../components';
import styled from 'styled-components';

import { Container as BaseContainer } from 'semantic-ui-react';

// Import semantic UI stylesheets
import 'semantic-ui-css/semantic.min.css';

import { LandingPage, ChangePasswordPage, VerifyEmailPage } from '../pages';
import { SalonLayout } from './salon';
import { SupplierLayout } from './supplier';

const Container = styled(BaseContainer)`
    height: 100%;
`;

const App = () => (
    <Container fluid>
        <Route exact path="/" component={LandingPage} />
        <Route
            exact
            path="/change-password/:token"
            component={ChangePasswordPage}
        />
        <Route
            exact
            path="/verify-email/:email/:token"
            component={VerifyEmailPage}
        />
        <AuthenticatedRoute path="/salon" component={SalonLayout} />
        <AuthenticatedRoute path="/supplier" component={SupplierLayout} />
    </Container>
);

export default App;
