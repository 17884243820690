/**
 * @flow
 */

import React from 'react';
import { Icon, Form, Input, Message } from 'semantic-ui-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Elements, injectStripe } from 'react-stripe-elements';
import { Field } from 'redux-form';

import { Modal, Button, FormField, FormLabel } from '../../../components';

import type { ModalDialog } from '../../../type';
import Label from '../../../components/FormLabel';
import Checkbox from '../../../components/Checkbox';

const InvoiceCheckbox = props => (
    <Checkbox
        {...props}
        label={
            <FormLabel>
                <FormattedMessage
                    id="pages.salon.SalonSusbscriptionDialog.needInvoice"
                    defaultMessage="I need a company invoice"
                />
            </FormLabel>
        }
    />
);

const fields = {
    input: FormField(Input),
    checkbox: FormField(InvoiceCheckbox),
};

type SalonSubscriptionDialogProps = ModalDialog;

type SalonSubscriptionFormProps = {
    elements: any,
    stripe: any,
    secret: any,
    onSubmit: any,
    needInvoice: boolean,
};

class _SalonSubscriptionForm extends React.Component {
    state = {
        error: null,
    };

    props: SalonSubscriptionFormProps;

    componentDidMount() {
        this.elements = this.props.stripe.elements({
            mode: 'setup',
            currency: 'eur',
        });
        this.cardElement = this.elements.create('payment');
        this.cardElement.mount(this.refs.card);
    }

    handleSubmit = async ev => {
        if (ev) {
            ev.preventDefault();
        }

        const { error: submitError } = await this.elements.submit();

        if (submitError) {
            console.log('err', submitError);
            this.setState({ error: submitError.message });
            return;
        }

        const { setupIntent, error } = await this.props.stripe.confirmSetup({
            elements: this.elements,
            clientSecret: this.props.secret,
            confirmParams: {
                return_url: window.location.href,
            },
            redirect: 'if_required',
        });

        if (error) {
            console.log('err', error);
            this.setState({ error: error.message });
            return;
        }

        this.props.onSubmit({ paymentMethod: setupIntent.payment_method });
    };

    render() {
        const { needInvoice } = this.props;
        const { error } = this.state;
        return (
            <Form onSubmit={this.handleSubmit} error={!!error}>
                {error && (
                    <Message error>
                        <Icon name="warning sign" />
                        {error}
                    </Message>
                )}
                <Form.Group>
                    <Form.Radio
                        label={
                            <Label>
                                <FormattedMessage
                                    id="pages.salon.SalonSusbscriptionDialog.price"
                                    defaultMessage="Monthly subscription {price} / month"
                                    values={{ price: '€24.20' }}
                                />
                            </Label>
                        }
                        value="1"
                        checked
                    />
                </Form.Group>
                <div className="ui hidden divider" />
                <div ref="card" />
                <div className="ui hidden divider" />
                <Form.Field>
                    <Form.Group>
                        <Field
                            name="needInvoice"
                            component={fields.checkbox}
                            simpleValue
                        />
                    </Form.Group>
                </Form.Field>
                {needInvoice ? (
                    <div>
                        <Form.Field>
                            <Form.Group widths="equal">
                                <Field
                                    name="companyName"
                                    component={fields.input}
                                    label={
                                        <FormLabel>
                                            <FormattedMessage
                                                id="pages.salon.SalonSusbscriptionDialog.companyName"
                                                defaultMessage="Company name"
                                            />
                                        </FormLabel>
                                    }
                                    simpleValue
                                />
                                <Field
                                    name="companyReg"
                                    component={fields.input}
                                    label={
                                        <FormLabel>
                                            <FormattedMessage
                                                id="pages.salon.SalonSusbscriptionDialog.companyReg"
                                                defaultMessage="Reg. number"
                                            />
                                        </FormLabel>
                                    }
                                    simpleValue
                                />
                            </Form.Group>
                        </Form.Field>
                        <Form.Field>
                            <Field
                                name="companyAddress"
                                component={fields.input}
                                label={
                                    <FormLabel>
                                        <FormattedMessage
                                            id="pages.salon.SalonSusbscriptionDialog.companyAddress"
                                            defaultMessage="Company address"
                                        />
                                    </FormLabel>
                                }
                                simpleValue
                            />
                        </Form.Field>
                    </div>
                ) : null}
            </Form>
        );
    }
}

export const SalonSubscriptionForm = injectStripe(_SalonSubscriptionForm, {
    withRef: true,
});

const SalonSubscriptionDialog = ({
    trigger,
    open,
    onClose,
    onSubmit,
    onRef,
    loading,
    secret,
    handleSubmit,
    needInvoice,
    ...props
}: SalonSubscriptionDialogProps) => {
    return (
        <Modal trigger={trigger} open={open} onClose={onClose} size="small">
            <Modal.Header>
                <FormattedMessage
                    id="pages.salon.SalonSusbscriptionDialog.subscription"
                    defaultMessage="Subscription"
                />
            </Modal.Header>
            <Modal.Content>
                <Elements mode="setup" currency="eur">
                    <SalonSubscriptionForm
                        {...props}
                        needInvoice={needInvoice}
                        ref={onRef}
                        secret={secret}
                        onSubmit={onSubmit}
                        loading={loading}
                    />
                </Elements>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    secondary
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={loading}
                >
                    <FormattedMessage
                        id="pages.salon.SalonSusbscriptionDialog.pay"
                        defaultMessage="Activate subscriptions"
                    />
                </Button>
                <Button onClick={onClose}>
                    <Icon name="remove" />
                    <FormattedMessage
                        id="pages.salon.SalonSusbscriptionDialog.cancel"
                        defaultMessage="Cancel"
                    />
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default injectIntl(SalonSubscriptionDialog);
