import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose, lifecycle, withState } from 'recompose';

import { defineMessages, injectIntl } from 'react-intl';
import VerifyEmailForm from '../components/VerifyEmailForm';

const intlMessages = defineMessages({
    defaultErrorMessage: {
        id: 'pages.clients.verifyeEmailForm.error',
        defaultMessage:
            'User with required token does not exists in our database',
    },
});

const VERIFY_EMAIL_QUERY = gql`
    mutation verifyEmailByToken($input: VerifyEmailByTokenInput!) {
        verifyEmailByToken(input: $input) {
            success
        }
    }
`;

const withData = graphql(VERIFY_EMAIL_QUERY, {
    props: ({ mutate, ownProps }) => ({
        onSubmit: () => {
            const mutation = mutate({
                variables: {
                    input: {
                        token: ownProps.token,
                        email: ownProps.email,
                    },
                },
            });

            return (
                mutation
                    // Update state of password reset
                    .then(() => {
                        ownProps.history.push('/salon/appointments');
                    })
                    // Handle possible errors
                    .catch(() => {
                        ownProps.setError(
                            ownProps.intl.formatMessage(
                                intlMessages.defaultErrorMessage,
                            ),
                        );
                    })
            );
        },
    }),
});

const withLifeCycle = lifecycle({
    componentDidMount() {
        this.props.onSubmit();
    },
});

export default compose(
    withRouter,
    injectIntl,
    withState('error', 'setError', null),
    withData,
    withLifeCycle,
)(VerifyEmailForm);
