/**
 * @flow
 */

import React from 'react';
import { compose, branch, renderComponent } from 'recompose';
import styled from 'styled-components';
import { Dropdown, Loader } from 'semantic-ui-react';

import { Avatar } from '../../../components';
import LogoutItem from '../../containers/userMenu/LogoutItem';
import ProfileItem from './ProfileItem';

import type { User } from '../../../type';
import VerifyEmailDialog from '../../../pages/administrators/containers/details/VerifyEmailDialog';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

type UserMenuProps = {
    user: User,
};

const AvatarWrapper = styled.span`
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
`;

const UserMenu = ({ user }: UserMenuProps) => (
    <Dropdown
        trigger={
            <AvatarWrapper>
                {`${user.firstName} ${user.lastName}`}
                <Avatar
                    size={30}
                    name={`${user.firstName} ${user.lastName}`}
                    spaced="left"
                    round
                />
            </AvatarWrapper>
        }
        icon={null}
    >
        <Dropdown.Menu>
            <ProfileItem administrator={user} />
            <VerifyEmailDialog
                administrator={user}
                open={user && !user.emailVerified}
            />
            <Dropdown.Item
                as={Link}
                to="/salon/invoices"
                icon="newspaper"
                text={
                    <FormattedMessage
                        id="pages.administrator.InvoicesPage.title"
                        defaultMessage="Invoices"
                    />
                }
            />
            <LogoutItem />
        </Dropdown.Menu>
    </Dropdown>
);

// Show spinner while loading user details
const withSpinner = branch(
    ownProps => ownProps.loading,
    renderComponent(() => <Loader size="small" active />),
);

export default compose(withSpinner)(UserMenu);
