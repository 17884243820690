/**
 * @flow
 */

import React from 'react';
import { Message, Icon } from 'semantic-ui-react';

const VerifyEmailForm = ({ error }: any) => (
    <div>
        {error && (
            <Message error>
                <Icon name="warning sign" />
                {error}
            </Message>
        )}
    </div>
);

export default VerifyEmailForm;
