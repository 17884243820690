import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Container as BaseContainer,
  Label as BaseLabel,
} from 'semantic-ui-react';
import styled from 'styled-components';
import moment from 'moment';
import { Button } from '../../../components';
import withModal from '../../../lib/withModal';
import type { ModalDialogTrigger } from '../../../type';
import SalonSubscriptionDialog from '../containers/SalonSubscriptionDialog';

const Container = styled(BaseContainer)`
    &.container {
        background: red;
        margin-top: -1rem;
        margin-bottom: 1rem;
        padding: 0.5rem 0;
        background: #ff1f02;
    }
    &.warning {
        background: #ff9900;
    }
`;

const Content = styled(BaseContainer)`
    &.container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`;

const Label = styled(BaseLabel)`
    &.label {
        font-weight: bold;
        padding: 0;
        margin-right: 1rem;
        background: none;
        color: #fff;
    }
`;

const SubscriptionButton = withModal(
  ({ open, onOpen, onClose, salon }: ModalDialogTrigger) => (
    <SalonSubscriptionDialog
      salon={salon}
      trigger={
        <Button onClick={onOpen}>
          <FormattedMessage
            id="pages.salon.SalonNotice.action"
            defaultMessage="Choose plan"
          />
        </Button>
      }
      open={open}
      onClose={onClose}
    />
  ),
);

const SubscriptionNotice = props => {
  const { salon, intl } = props;
  if (!salon || salon.subscription.active) {
    return null;
  }

  let trial = false;
  let days = 0;
  let daysMsg = '';
  if (
    salon.subscription.trialEndsAt &&
    moment(new Date(parseInt(salon.subscription.trialEndsAt, 10))).isAfter(
      new Date(),
    )
  ) {
    days = moment(
      new Date(parseInt(salon.subscription.trialEndsAt, 10)),
    ).diff(moment(), 'd');
    trial = true;
    const daysRule = intl.formatPlural(days);
    const daysMessage = intl.formatMessage({
      id: 'pages.salon.SalonNotice.days',
      defaultMessage: {
        one: '1 day',
        other: '{count} days',
      },
    });
    daysMsg = (daysMessage[daysRule] || daysMessage.other).replace(
      '{count}',
      days,
    );
  }

  return (
    <Container fluid className={trial ? 'warning' : ''}>
      <Content>
        <Label>
          {trial ? (
            <FormattedMessage
              id="pages.salon.SalonNotice.trial"
              defaultMessage="Your trial ends in {count}. Choose payment method to proceed."
              values={{
                count: daysMsg,
              }}
            />
          ) : (
            <FormattedMessage
              id="pages.salon.SalonNotice.ended"
              defaultMessage="Your trial has ended. Choose plan to proceed."
            />
          )}
        </Label>
        <SubscriptionButton salon={salon} />
      </Content>
    </Container>
  );
};

export default injectIntl(SubscriptionNotice);
