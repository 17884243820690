import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { indexOf, isEqual } from 'lodash';

import UserMenu from '../../components/userMenu/UserMenu';
import { setIsSysadmin, setUserData } from '../../../actions/user';

const GET_USER_QUERY = gql`
    query getUser {
        viewer {
            id
            ... on Administrator {
                firstName
                lastName
                email
                phone
                roles
                emailVerified
            }
            ... on Supplier {
                firstName
                lastName
                email
                phone
                roles
            }
            ... on Master {
                firstName
                lastName
                email
                phone
                roles
            }
        }
    }
`;

const withData = graphql(GET_USER_QUERY, {
    props: ({ data: { loading, viewer } }) => ({
        loading,
        user: viewer,
    }),
});

const mapDispatchToProps = {
    setIsSysadmin,
    setUserData,
};

const componentWillReceiveProps = ({
    loading,
    user,
    userData,
    setIsSysadmin,
    setUserData,
}) => {
    setIsSysadmin(!loading && user && indexOf(user.roles, 'SYSADMIN') !== -1);
    const data = ({ firstName, lastName, phone, email }) => ({
        firstName,
        lastName,
        phone,
        email,
    });
    if (!isEqual(data(user), userData)) setUserData(data(user));
};

const mapStateToProps = ({ user }) => ({
    userData: user.get('userData'),
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withData,
    lifecycle({
        componentWillReceiveProps,
    }),
)(UserMenu);
