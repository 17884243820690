import React from 'react';
import Raven from 'raven-js';
import config from './config';

import { ApolloProvider, IntlProvider, ReduxProvider } from './providers';
import { BrowserRouter } from 'react-router-dom';
import { App } from './layouts';
import { StripeProvider } from 'react-stripe-elements';

// Initialize sentry error tracker
Raven.config(
    config.sentry.url,
    Object.assign({}, config.sentry, {
        environment: process.env.NODE_ENV,
    }),
).install();

const Root = () => (
    <ReduxProvider>
        <IntlProvider>
            <BrowserRouter>
                <ApolloProvider>
                    <StripeProvider apiKey={config.stripe.public}>
                        <App />
                    </StripeProvider>
                </ApolloProvider>
            </BrowserRouter>
        </IntlProvider>
    </ReduxProvider>
);

export default Root;
