/**
 * @flow
 */

import { reduxForm, SubmissionError } from 'redux-form';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import uuid from 'uuid';
import { injectIntl, defineMessages } from 'react-intl';
import { some, get, find } from 'lodash';

import { setIsOwner, switchSalon } from '../../../actions/user';
import CreateSalonDialog from '../components/CreateSalonDialog';
import validate from './validateCreateSalon';
import withNewSalon from '../../../lib/withNewSalon';

import { GET_SALONS_QUERY } from './SalonSelect';
import { GET_SALON_LIST_QUERY } from '../../../pages/salon-list/containers/SalonList';

const intlMessages = defineMessages({
    nameExistingsError: {
        id: 'pages.settings.generalSettings.nameExistingsError',
        defaultMessage: 'Salon with the same name already exists',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

// The query to create a new salon
const CREATE_SALON_MUTATION = gql`
    mutation createSalon($input: CreateSalonInput!) {
        createSalon(input: $input) {
            salon {
                id
                name
                address
                administrator {
                    id
                    firstName
                    lastName
                    phone
                    email
                }
                masterCount
                settings {
                    schedule {
                        startAt
                        endAt
                        step
                        format
                    }
                    enableArchive
                }
                subscription {
                    active
                    subId
                    customerId
                    priceId
                    trialEndsAt
                }
            }
        }
    }
`;

const withData = graphql(CREATE_SALON_MUTATION, {
    props: ({ mutate, ownProps }) => ({
        onSubmit: formData => {
            const mutation = mutate({
                variables: {
                    input: formData,
                },
                optimisticResponse: {
                    __typename: 'Mutation',
                    createSalon: {
                        __typename: 'CreateSalonPayload',
                        salon: {
                            __typename: 'Salon',
                            id: uuid.v4(),
                            name: formData.name,
                            address: formData.address,
                            administrator: {
                                __typename: 'Administrator',
                                id: ownProps.user,
                                ...ownProps.userData,
                            },
                            masterCount: 4,
                            settings: {
                                __typename: 'Settings',
                                schedule: {
                                    __typename: 'Schedule',
                                    startAt: 480,
                                    endAt: 1200,
                                    step: 30,
                                    format: 'HH:mm',
                                },
                                enableArchive: false,
                            },
                        },
                    },
                },
                update: (store, { data: { createSalon } }) => {
                    const data = store.readQuery({
                        query: GET_SALONS_QUERY,
                    });
                    data.viewer.salons.push(createSalon.salon);
                    store.writeQuery({
                        query: GET_SALONS_QUERY,
                        data,
                    });

                    const inCache = some(store.watches, item =>
                        find(
                            get(item, 'query.definitions', []),
                            item =>
                                get(item, 'name.value', '') === 'getSalonList',
                        ),
                    );

                    if (inCache) {
                        const salonList = store.readQuery({
                            query: GET_SALON_LIST_QUERY,
                            variables: {
                                resolveRole: 'SYSADMIN',
                            },
                        });

                        // default master count
                        createSalon.salon.masterCount = 4;

                        salonList.viewer.salonList.unshift(createSalon.salon);

                        store.writeQuery({
                            query: GET_SALON_LIST_QUERY,
                            variables: {
                                resolveRole: 'SYSADMIN',
                            },
                            data: salonList,
                        });
                    }
                },
            });

            const { intl } = ownProps;

            return mutation
                .then(({ data: { createSalon } }) => {
                    ownProps.setupNewSalon(createSalon.salon.id, () => {
                        ownProps.setOwnerAsMaster(
                            createSalon.salon.id,
                            ownProps.user,
                            () => {
                                ownProps.setIsOwner(true);
                                ownProps.switchSalon(
                                    createSalon.salon.id,
                                    createSalon.salon.name,
                                );
                                ownProps.onClose();
                            },
                        );
                    });
                })
                .catch(error => {
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];
                    if (graphQLError) {
                        if (
                            graphQLError.name === 'AlreadyExists' &&
                            graphQLError.data.error === 'NAME_ALREADY_EXISTS'
                        ) {
                            throw new SubmissionError({
                                name: intl.formatMessage(
                                    intlMessages.nameExistingsError,
                                ),
                            });
                        }
                    }

                    throw new SubmissionError({
                        _error: intl.formatMessage(
                            intlMessages.defaultErrorMessage,
                        ),
                    });
                });
        },
    }),
});

const withForm = reduxForm({
    form: 'createSalon',
    enableReinitialize: true,
    validate,
});

const withInitialValues = withProps(
    ({ open }) =>
        open && {
            initialValues: {},
        },
);

const mapStateToProps = ({ user }) => ({
    user: user.get('id'),
    userData: user.get('userData'),
});

export default compose(
    connect(
        mapStateToProps,
        {
            setIsOwner,
            switchSalon,
        },
    ),
    injectIntl,
    withNewSalon,
    withData,
    withInitialValues,
    withForm,
)(CreateSalonDialog);
