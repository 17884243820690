import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withApollo } from 'react-apollo';
import { logout } from '../../../../actions/user';
import VerifyEmailDialog from '../../components/details/VerifyEmailDialog';

export default compose(
    withApollo,
    connect(
        null,
        (dispatch, ownProps) => ({
            logout: () => {
                dispatch(logout());
                setTimeout(() => {
                    ownProps.client.resetStore();
                }, 0);
            },
        }),
    ),
)(VerifyEmailDialog);
