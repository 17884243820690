import { compose } from 'recompose';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import SubscriptionNotice from '../components/SubscriptionNotice';

const GET_SALON_QUERY = gql`
    query getSalon($salon: ID!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    name
                    address
                    settings {
                        schedule {
                            startAt
                            endAt
                            step
                            format
                        }
                    }
                    subscription {
                        active
                        subId
                        customerId
                        priceId
                        trialEndsAt
                    }
                }
            }
        }
    }
`;

const withData = graphql(GET_SALON_QUERY, {
  options: ownProps => ({
    variables: {
      salon: ownProps.salonId,
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ data: { loading, viewer } }) => ({
    salon: !loading ? viewer.salon : null,
  }),
});

const mapStateToProps = ({ user }) => {
  return {
    user: user.get('id'),
    salonId: user.get('salon'),
    isOwner: user.get('isOwner'),
  };
};

export default compose(
  connect(mapStateToProps),
  withData,
)(SubscriptionNotice);
