/**
 * @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Modal, Avatar, Button } from '../../../../components';

import type { ModalDialog, Administrator } from '../../../../type';

type VerifyEmailDialogProps = {
    administrator: Administrator,
} & ModalDialog;

const VerifyEmailDialog = ({
    administrator,
    trigger,
    onClose,
    open,
    logout,
}: VerifyEmailDialogProps) => (
    <Modal onClose={onClose} open={open} trigger={trigger}>
        <Modal.Header>
            <Avatar
                name={`${administrator.firstName} ${administrator.lastName}`}
                size={24}
                spaced="right"
                round
            />
            {administrator.firstName}
            {' '}
            {administrator.lastName}
        </Modal.Header>
        <Modal.Context>
            <Modal.Content>
                <FormattedMessage
                    id="pages.administrators.form.verifyEmail"
                    defaultMessage="You must verify your email. We have sent you an email with confirmation link to {email}"
                    values={{
                        email: administrator.email,
                    }}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={logout}>
                    <FormattedMessage
                        id="navbar.menu.signOut"
                        defaultMessage="Sign Out"
                    />
                </Button>
            </Modal.Actions>
        </Modal.Context>
    </Modal>
);

export default VerifyEmailDialog;
