/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import {
    compose,
    lifecycle,
    withHandlers,
    withProps,
    withState,
} from 'recompose';
import { defineMessages, injectIntl } from 'react-intl';
import { isEqual, get } from 'lodash';

import { SubmissionError, formValueSelector, reduxForm } from 'redux-form';
import moment from 'moment';
import { setIsOwner, switchSalon } from '../../../actions/user';
import SalonSubscriptionDialog from '../components/SalonSubscriptionDialog';
import client from '../../../apollo-client';

const intlMessages = defineMessages({
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

const GET_STRIPE_INTENT_MUTATION = gql`
    mutation createStripeInput($input: StripeIntentInput!) {
        createStripeIntent(input: $input) {
            clientSecret
        }
    }
`;

const UPDATE_STRIPE_SUBSCRIPTION_MUTATION = gql`
    mutation updateStripeSubscription($input: StripeSubscriptionInput!) {
        updateStripeSubscription(input: $input) {
            success
        }
    }
`;

const withMutation = graphql(GET_STRIPE_INTENT_MUTATION, {
    options: ownProps => ({
        variables: {
            salonId: ownProps.salonId,
        },
    }),
    props: ({ mutate, ownProps: { salonId, setSecret } }) => ({
        createStripeIntent: () => {
            const mutation = mutate({
                variables: {
                    input: {
                        salon: salonId,
                    },
                },
            }).then(({ data }) => {
                setSecret(data.createStripeIntent.clientSecret);
            });
            return mutation;
        },
    }),
});

const withUpdateMutation = graphql(UPDATE_STRIPE_SUBSCRIPTION_MUTATION, {
    options: ownProps => ({
        variables: {
            salonId: ownProps.salonId,
        },
    }),
    props: ({ mutate, ownProps: { salonId, onClose, intl } }) => ({
        updateStripeSubscription: (
            paymentMethod,
            needInvoice,
            companyName,
            companyReg,
            companyAddress,
        ) => {
            const mutation = mutate({
                variables: {
                    input: {
                        salon: salonId,
                        paymentMethod,
                        needInvoice,
                        companyName,
                        companyReg,
                        companyAddress,
                    },
                },
            })
                .then(({ data }) => {
                    if (data.updateStripeSubscription.success) {
                        onClose();
                        client.reFetchObservableQueries(false);
                    }
                })
                .catch(() => {
                    alert(intl.formatMessage(intlMessages.defaultErrorMessage));
                });
            return mutation;
        },
    }),
});

const withModalHandlers = withHandlers(() => {
    let formRef = null;
    return {
        onRef: () => ref => {
            formRef = ref;
        },
        onSubmit: ({
            setLoading,
            updateStripeSubscription,
            needInvoice,
            companyName,
            companyReg,
            companyAddress,
        }) => ({ paymentMethod }) => {
            setLoading(true);
            updateStripeSubscription(
                paymentMethod,
                needInvoice,
                companyName,
                companyReg,
                companyAddress,
            ).finally(() => {
                setLoading(false);
            });
        },
        handleSubmit: ({ setLoading }) => async () => {
            setLoading(true);
            await formRef.getWrappedInstance().handleSubmit();
            setLoading(false);
        },
    };
});

const withForm = reduxForm({
    form: 'salonSubscription',
    touchOnBlur: false,
    enableReinitialize: true,
});

const withInitialValues = withState('initialValues', 'setInitialValues', {});
const withLoading = withState('loading', 'setLoading', false);

const withSecret = withState('secret', 'setSecret', null);

const selector = formValueSelector('salonSubscription');
const mapStateToProps = ({ user, ...state }) => {
    return {
        user: user.get('id'),
        salonId: user.get('salon'),
        isOwner: user.get('isOwner'),
        userData: user.get('userData'),
        needInvoice: selector(state, 'needInvoice'),
        companyName: selector(state, 'companyName'),
        companyReg: selector(state, 'companyReg'),
        companyAddress: selector(state, 'companyAddress'),
    };
};

const withLifeCycle = lifecycle({
    componentWillReceiveProps({ open, isOwner, createStripeIntent }) {
        if (open && isOwner && !this.props.open) {
            createStripeIntent();
        }
    },
});

export default compose(
    connect(
        mapStateToProps,
        {
            setIsOwner,
            switchSalon,
        },
    ),
    injectIntl,
    withSecret,
    withLoading,
    withMutation,
    withUpdateMutation,
    withModalHandlers,
    withLifeCycle,
    withInitialValues,
    withForm,
)(SalonSubscriptionDialog);
