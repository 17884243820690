/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { compose, branch, renderComponent, toClass } from 'recompose';
import { isEmpty, each } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Button, Loader } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroller';
import { Table, Avatar, EllipsisLoader } from '../../../components';

import type { StripeInvoice, StripeInvoiceEdge, PageInfo } from '../../../type';

type InvoicesListProps = {
    invoices: Array<StripeInvoiceEdge>,
    loadMore: Function,
    pageInfo: PageInfo,
};

type InvoiceRowProps = {
    invoice: StripeInvoice,
};

const EmptyMessage = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={3} textAlign="center">
            <FormattedMessage
                id="pages.administrators.InvoicesList.noInvoices"
                defaultMessage="You don't have any invoices yet."
            />
        </Table.Cell>
    </Table.Row>
);

const LoadMoreSpinner = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={3} textAlign="center">
            <EllipsisLoader size="1em" />
        </Table.Cell>
    </Table.Row>
);

const InvoiceRow = ({ invoice }: InvoiceRowProps) => {
    return (
        <Table.Row>
            <Table.Cell>{invoice.number}</Table.Cell>
            <Table.Cell>{(invoice.total / 100).toFixed(2)}</Table.Cell>
            <Table.Cell>
                <Button primary href={invoice.invoicePdf} target="_blank">
                    <FormattedMessage
                        id="pages.administrators.InvoicesList.download"
                        defaultMessage="Download"
                    />
                </Button>
            </Table.Cell>
        </Table.Row>
    );
};

const InvoicesList = ({ invoices, pageInfo, loadMore }: InvoicesListProps) => {
    return (
        <Table celled selectable striped fixed>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        <FormattedMessage
                            id="pages.administrators.InvoicesList.number"
                            defaultMessage="Number"
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage
                            id="pages.administrators.InvoicesList.total"
                            defaultMessage="Total"
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage
                            id="pages.administrators.InvoicesList.actions"
                            defaultMessage="Actions"
                        />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <InfiniteScroll
                element="tbody"
                pageStart={0}
                loadMore={loadMore}
                initialLoad={false}
                hasMore={pageInfo.hasNextPage}
                loader={<LoadMoreSpinner key={0} />}
                threshold={1}
                useWindow
            >
                {isEmpty(invoices) ? (
                    <EmptyMessage />
                ) : (
                    invoices.map(invoice => (
                        <InvoiceRow
                            key={invoice.cursor}
                            invoice={invoice.node}
                            pageInfo={pageInfo}
                        />
                    ))
                )}
            </InfiniteScroll>
        </Table>
    );
};

const withSpinner = branch(
    props => props.loading && isEmpty(props.invoices),
    renderComponent(() => <Loader size="big" inline="centered" active />),
);

export default compose(
    withSpinner,
    toClass,
)(InvoicesList);
