/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';

import { Container, Grid, Menu as BaseMenu, Header } from 'semantic-ui-react';
import { Divider, PageHeader } from '../../../components';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import InvoicesList from '../containers/InvoicesList';

const Menu = styled(BaseMenu)`
    &.menu {
        margin: -0.5em -1em !important;
    }
`;

const InvoicesPage = ({ intl, summary, loading }) => (
    <Container fluid>
        <Menu secondary>
            <BaseMenu.Item header>
                <PageHeader>
                    <FormattedMessage
                        id="pages.administrators.InvoicesPage.title"
                        defaultMessage="Invoices"
                    />
                </PageHeader>
            </BaseMenu.Item>
        </Menu>
        <Divider />
        <Grid columns={1}>
            <Grid.Column>
                <InvoicesList />
            </Grid.Column>
        </Grid>
    </Container>
);

export default injectIntl(InvoicesPage);
