import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { get } from 'lodash';
import InvoicesList from '../components/InvoicesList';

// Query for list of clients for current salon
export const GET_INVOICES_QUERY = gql`
    query getInvoices($salon: ID!, $first: Int, $after: String) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    invoices(first: $first, after: $after) {
                        pageInfo {
                            endCursor
                            hasNextPage
                        }
                        edges {
                            cursor
                            node {
                                invoicePdf
                                total
                                status
                                number
                                periodStart
                                periodEnd
                            }
                        }
                    }
                }
            }
        }
    }
`;

const withData = graphql(GET_INVOICES_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
            first: 20,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { viewer, loading, fetchMore } }) => ({
        loading,
        fetchMore,
        invoices: get(viewer, 'salon.invoices.edges', []),
        pageInfo: get(viewer, 'salon.invoices.pageInfo', {}),
    }),
});

const mapStateToProps = ({ user }) => ({
    salon: user.get('salon'),
});

const handlers = withHandlers({
    /**
     * Handle load more results event by requesting data after
     * end cursor from the last request.
     */
    loadMore: ownProps => () => {
        const { loading, salon, pageInfo, fetchMore } = ownProps;

        // Skip if we already loading results or that was the last page
        if (loading || !pageInfo.hasNextPage) {
            return false;
        }

        fetchMore({
            query: GET_INVOICES_QUERY,
            variables: {
                salon,
                first: 20,
                after: pageInfo.endCursor,
            },
            // Merge current results with a new results
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const prevEdges = previousResult.viewer.salon.clients.edges;
                const newEdges = fetchMoreResult.viewer.salon.clients.edges;

                const newResults = Object.assign({}, fetchMoreResult);
                newResults.viewer.salon.clients.edges = [
                    ...prevEdges,
                    ...newEdges,
                ];

                return newResults;
            },
        });
    },
});

export default compose(
    connect(mapStateToProps),
    withData,
    handlers,
)(InvoicesList);
